
import HeaderTit from '@/components/HeaderTit.vue'
import PreView from '@/components/preview/PreView.ts'

import { onMounted, defineComponent, reactive, ref } from 'vue'
import AxiosAdapter from '../request/AxiosAdapter'
import { useRoute, useRouter } from 'vue-router'
import { getRecordId } from '../commons/Utils'
export default defineComponent({
  name: 'DocumentDetail',
  components: {
    HeaderTit
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    getRecordId(route.query.id, '查看文书详情', true)
    const deliveryInfo = reactive({
      caseNumberWord: '',
      caseType: '',
      caseCauseName: '',
      time: '',
      sendPerson: '',
      sendTime: ''
    })
    const signingStatus = reactive({
      status: '',
      receiveType: '',
      person: '',
      time: ''
    })
    const fileUrl = ref()
    const documentList: any = reactive([])

    const previewFun = (item: any) => {
      PreView(item, router)
      fileUrl.value = item
    }
    const webElectronicDetailFun = async (id: any) => {
      const data = await AxiosAdapter.get<any, any>({
        url: '/webElectronic/webElectronicDetail',
        data: { electronicId: id }
      })

      deliveryInfo.caseNumberWord = data.caseNumberWord
      deliveryInfo.caseType = data.caseTypeName
      deliveryInfo.caseCauseName = data.caseCauseName
      deliveryInfo.time = data.expireTime
      deliveryInfo.sendPerson = data.courtName
      deliveryInfo.sendTime = data.sendTime

      signingStatus.status = data.signStatus
      signingStatus.receiveType = data.receiveType
      signingStatus.person = data.receiverName
      signingStatus.time = data.receiverTime
      if (data.documentList && data.documentList.length > 0) {
        documentList.values = data.documentList
      }
    }
    const downloadFun = (item: any) => {
      window.open(
        process.env.VUE_APP_BASE_API +
          '/file/fileDownLoad?fileUrl=' +
          encodeURI(item)
      )
    }
    onMounted(() => {
      webElectronicDetailFun(route.query.id)
    })
    return {
      webElectronicDetailFun,
      deliveryInfo,
      signingStatus,
      documentList,
      downloadFun,
      previewFun
    }
    //
  }
})
